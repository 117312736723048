import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

//import { JourneyAuditLogs } from './pages';
import sagas from './sagas';


const JourneyAuditLogs = lazy(() => import('./pages/JourneyAuditLogs'));

const auditLogsModule = {
  routes: [
    <AuthRoute key="audit-logs-journey" exact path="/audit-logs/journey/:journeyId" component={JourneyAuditLogs} headerType={'private'} isSpecialistOnly />,
  ],
  /*navMenuItems: [
    {
      name: 'Communications',
      path: '/communications',
      isForSpecialist: true,
      order: 1
    },
  ],*/
  sagas
};

export default auditLogsModule;
