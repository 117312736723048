import {call, debounce, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '@utils/functions';

const Api = {
  getAuditLogs: ({
                   limit,
                   page,
                   sort,
                   entityId,
                   entityType,
                   user,
                   shortDescription
  }) => {
    let params = {
      limit: limit ?? 10
    };
    if (page || page === 0) params.page = page;
    if (sort) params.sort = sort;
    if (entityId) params.entity_id = entityId;
    if (entityType) params.entity_type = entityType;
    if (user) params.user = user;
    if (shortDescription) params.short_description = shortDescription;

    return axios.request({
      url: '/auditLog',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getAuditLog: (id) => axios.get(`/auditLog/${id}`),
};

export const actionTypes = {
  GET_AUDIT_LOGS: 'GET_AUDIT_LOGS',
  GET_AUDIT_LOGS_WITH_DEBOUNCE: 'GET_AUDIT_LOGS_WITH_DEBOUNCE',
  GET_AUDIT_LOG: 'GET_AUDIT_LOG',
};

function* sagaGetAuditLogs({ payload: {
  limit,
  page,
  sort,
  entityId,
  entityType,
  user,
  shortDescription,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getAuditLogs, {
      limit,
      page,
      sort,
      entityId,
      entityType,
      user,
      shortDescription,
    });

    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAuditLog({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAuditLog, id);
    if (result.data) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getAuditLogs = payload => ({ type: actionTypes.GET_AUDIT_LOGS, payload });
export const getAuditLogsWithDebounce = payload => ({ type: actionTypes.GET_AUDIT_LOGS_WITH_DEBOUNCE, payload });
export const getAuditLog = payload => ({ type: actionTypes.GET_AUDIT_LOG, payload });

const auditLogsSagas = [
  takeLatest(actionTypes.GET_AUDIT_LOGS, sagaGetAuditLogs),
  debounce(900, actionTypes.GET_AUDIT_LOGS_WITH_DEBOUNCE, sagaGetAuditLogs),
  takeLatest(actionTypes.GET_AUDIT_LOG, sagaGetAuditLog),
];

export default auditLogsSagas;
